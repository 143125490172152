import pluginsService from "@/services/plugins";

export default {
  name: "plugins",
  components: {},
  props: [],
  data() {
    return {
      items: [],
      baseUrl: ""
    };
  },
  computed: {},
  mounted() {
    this.baseUrl = process.env.VUE_APP_ASSETS_URL;
    this.getList();
  },
  methods: {
    getList() {
      pluginsService
        .getPluginsList()
        .then(response => {
          this.items = response.data;
        })
        .catch(error => {
          let errorObject = error.response.data.data;
          const keys = Object.keys(errorObject.errors);
          keys.forEach(key => {
            this.$bvToast.toast(errorObject.errors[key], {
              title: this.$i18n.t("toast.errorTitle"),
              noCloseButton: true,
              variant: "custom",
              bodyClass: "dangerBackgroundColor dangerFontColor rtl text-right",
              headerClass:
                "dangerBackgroundColor dangerFontColor  rtl text-right",
              solid: false
            });
          });
        });
    }
  }
};
